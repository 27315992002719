<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h3>Business Cards</h3>
      </v-card-title>
      <v-card-text>
        <ul>
          <li v-for="(item, i) in cards" :key="i">
            <a :href="item.path">{{item.name}}</a>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    cards: [],
  }),
  created() {
    console.log(this.$router)
  },
  mounted() {
    this.cards = this.$router.options.routes.filter(x => {
      if (x.props) return true;
      return false;
    })
  }
}
</script>

<style scoped>

</style>